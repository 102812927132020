import React from "react";
import StepIcon from "./stepIcon";

const HowItWorks = () => (
  <div className="w-full bg-black text-dark-light-grey">
    <div
      className="mx-auto px-48 sm:px-24 py-48 md:px-32 lg:py-72 xlg:py-116"
      style={{ maxWidth: "1440px" }}
    >
      <div className="landing-subtitle text-center">how szns works</div>
      <div className="flex flex-col md:flex-row justify-between mt-48 lg:mt-72">
        <div
          className="flex flex-col space-y-64 text-center mx-auto md:pt-24 lg:pt-64"
          style={{ maxWidth: "296px" }}
        >
          <h1>For Album Creators</h1>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={1} />
            <div>
              <p className="landing-text">Start with a selection of NFTs</p>
              <p className="landing-text">to create an Album DAO</p>
            </div>
          </div>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={2} />
            <div>
              <p className="landing-text">
                Sell a selected percentage of Album Tokens
              </p>
              <p className="landing-text">to share ownership of the NFTs</p>
            </div>
          </div>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={3} />
            <div>
              <p className="landing-text">Build your community around</p>
              <p className="landing-text">the curated selection of NFTs!</p>
            </div>
          </div>
        </div>
        <span className="border md:border-r-2 border-dark-white-64 my-64 md:my-0" />
        <div
          className="flex flex-col space-y-64 text-center mx-auto sm:mb-32 md:mb-96 md:pt-24 lg:pt-64"
          style={{ maxWidth: "296px" }}
        >
          <h1>For NFT Collectors</h1>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={1} />
            <div>
              <p className="landing-text">Purchase Album tokens</p>
              <p className="landing-text">and join the Album DAO</p>
            </div>
          </div>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={2} />
            <div>
              <p className="landing-text">Participate in the management</p>
              <p className="landing-text">of the NFTs inside the Album</p>
            </div>
          </div>
          <div className="flex flex-col space-y-16 items-center">
            <StepIcon step={3} />
            <p className="body1">
              Continue to curate the Album as an ongoing collective
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorks;
