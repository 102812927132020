import React from "react";

type MemberProps = {
  image: string;
  social: string;
};

const Member = ({ image = "", social = "" }: MemberProps) => {
  return (
    <a
      href={`https://twitter.com/${social}`}
      target="_blank"
      className="text-black hover:text-white min-w-128 lg:min-w-0"
    >
      <div className="flex flex-col space-y-16 items-center">
        <div className="rounded-full bg-transparent w-96 h-96 xlg:w-124 xlg:h-124">
          <img src={image} style={{ borderRadius: "inherit" }} />
        </div>
        <p className="body2">@{social}</p>
      </div>
    </a>
  );
};

export default Member;
