import { Link } from "gatsby";
import React from "react";
import Button from "../common/button/button";

import CardImg from "../../images/landingPage/topCards@2x.png";

const HomeBanner = () => (
  <div
    className="flex w-full landing-bg text-black justify-center"
    // style={{ minHeight: "80vh" }}
  >
    <div
      className="flex flex-col-reverse justify-between md:flex-row py-24 sm:px-16 md:px-32 lg:px-72 md:py-64 lg:py-72 xlg:py-116 w-full"
      style={{ maxWidth: "1440px" }}
    >
      <div
        // className="flex flex-col mx-16 lg:mr-0 md:ml-72 mt-32 md:mt-0"
        className="flex flex-col justify-center mt-32 md:mt-0 mr-0 sm:w-full md:w-7/12"
      >
        <div className="banner-title">COLLECTIVELY OWN AND</div>
        <div className="banner-title">MANAGE NFTS, TOGETHER</div>
        <p className="banner-text mt-24 max-w-full md:max-w-90% lg:max-w-94%">
          Collect and manage NFTs together as a DAO. Trusted by leaders like
          DivineDAO, MeebitsDAO, and more
        </p>
        <Link
          className="mt-32 w-343 sm:pb-24 md:pb-0 w-full md:w-270 mx-auto md:mx-0 hover:opacity-80"
          to="/explore"
        >
          <Button width="full" shadow={true}>
            Explore Albums
          </Button>
        </Link>
      </div>
      <div
        className="md:w-5/12"
        // style={{ height: "347.48px", width: "496.26px", marginLeft: "57.33px", marginRight: "78.07px" }}
      >
        <img
          className="mx-auto md:mx-0"
          style={{ maxHeight: "347.48px" }}
          src={CardImg}
        />
      </div>
    </div>
  </div>
);

export default HomeBanner;
