import React from "react";
import LaunchPartner from "./launchPartner";

//Divine DAO
import DivineDaoLeftImg from "../../../images/landingPage/launchPartners/launch-partner_divinedao_left@2x.jpg";
import DivineDaoRightImg from "../../../images/landingPage/launchPartners/launch-partner_divinedao_right@2x.jpg";
import DivineDaoLogoImg from "../../../images/landingPage/launchPartners/divinedao.gif";
// import DivineDaoLogoImg from "../../../images/landingPage/launchPartners/launch-partner_divinedao_logo@2x.png"
//Mr misang
import MisangLeftImg from "../../../images/landingPage/launchPartners/launch-partner_misang_left@2x.jpg";
import MisangRightImg from "../../../images/landingPage/launchPartners/launch-partner_misang_right@2x.jpg";
import MisangLogoImg from "../../../images/landingPage/launchPartners/launch-partner_misang_logo@2x.gif";
//Meebits
import MeebitsLeftImg from "../../../images/landingPage/launchPartners/launch-partner_meebits_left@2x.jpg";
import MeebitsRightImg from "../../../images/landingPage/launchPartners/launch-partner_meebits_right@2x.jpg";
import MeebitsLogoImg from "../../../images/landingPage/launchPartners/launch-partner_meebits_logo@2x.png";
//Silver Surfer
import SilverPoetLeftImg from "../../../images/landingPage/launchPartners/launch-partner_SilverPoet_left@2x.jpg";
import SilverPoetRightImg from "../../../images/landingPage/launchPartners/launch-partner_SilverPoet_right@2x.jpg";
import SilverPoetLogoImg from "../../../images/landingPage/launchPartners/launch-partner_SilverPoet_logo@2x.png";
//Han Dao
import HanDaoLeftImg from "../../../images/landingPage/launchPartners/launch-partner_HanDAO_left@2x.jpg";
import HanDaosRightImg from "../../../images/landingPage/launchPartners/launch-partner_HanDAO_right@2x.jpg";
import HanDaoLogoImg from "../../../images/landingPage/launchPartners/launch-partner_HanDAO_logo@2x.png";
import Button from "../../common/button/button";
const LaunchPartnerContainer = () => (
  <div className="w-full flex justify-center">
    <div
      className="py-48 px-16 md:py-72 md:px-24 lg:py-96 lg:px-48"
      style={{ maxWidth: "1440px" }}
    >
      <h1 className="landing-subtitle text-center text-white">
        Launch Partners
      </h1>
      <div className="flex flex-col space-y-48 py-48 md:py-64">
        <LaunchPartner
          name="DivineDAO"
          leftImgUrl={DivineDaoLeftImg}
          rightImgUrl={DivineDaoRightImg}
          profileImgUrl={DivineDaoLogoImg}
          twitter="https://twitter.com/divine_dao"
        />
        <LaunchPartner
          name="Mr. Misang"
          leftImgUrl={MisangLeftImg}
          rightImgUrl={MisangRightImg}
          profileImgUrl={MisangLogoImg}
          twitter="https://twitter.com/MrMisang"
        />
        <LaunchPartner
          name="MeebitsDAO"
          leftImgUrl={MeebitsLeftImg}
          rightImgUrl={MeebitsRightImg}
          profileImgUrl={MeebitsLogoImg}
          twitter="https://twitter.com/meebitsdao"
        />
        <LaunchPartner
          name="Silver Surfer"
          leftImgUrl={SilverPoetLeftImg}
          rightImgUrl={SilverPoetRightImg}
          profileImgUrl={SilverPoetLogoImg}
          twitter="https://twitter.com/7Surfer7Silver7"
        />
        <LaunchPartner
          name="HanDAO"
          leftImgUrl={HanDaoLeftImg}
          rightImgUrl={HanDaosRightImg}
          profileImgUrl={HanDaoLogoImg}
          twitter="https://twitter.com/handao_org"
        />
        <div className="flex flex-col space-y-24 mx-auto text-white items-center">
          <h1>Artist, DAO, or Curator?</h1>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdKLbuN16WPWnCRhi5Y6AheTPJYly2PhQYzkCPs5rkwO6RKKQ/viewform?usp=sf_link"
            target="_blank"
          >
            <Button>Apply for early access</Button>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default LaunchPartnerContainer;
