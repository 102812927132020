import React from "react";

import DanImg from "../../../images/landingPage/team/dan@2x.png";
import JackImg from "../../../images/landingPage/team/jack.jpg";
import DuncanImg from "../../../images/landingPage/team/duncan.png";
import JohnImg from "../../../images/landingPage/team/john.png";
import SheanImg from "../../../images/landingPage/team/shean@2x.jpg";
import RobImg from "../../../images/landingPage/team/dsnchlz.jpeg";
import JamieImg from "../../../images/landingPage/team/jamie@2x.png";
import CraigImg from "../../../images/landingPage/team/craig.jpeg";
import Member from "./member";

const SznsTeam = () => (
  <div className="flex flex-col">
    <div className="landing-subtitle mb-48 text-center">team</div>
    <div className="flex flex-row flex-wrap gap-32 xlg:gap-48 team-container max-w-full lg:max-w-94% md:mx-auto justify-center">
      <Member image={JohnImg} social="jl2fa" />
      <Member image={JackImg} social="jack_dille" />
      <Member image={SheanImg} social="ioshean" />
      <Member image={DanImg} social="szns_dtree" />
      <Member image={RobImg} social="DSNChlz" />
      <Member image={DuncanImg} social="OKDunc" />
      <Member image={JamieImg} social="szns_jamiejames" />
      <Member image={CraigImg} social="craigjson" />
    </div>
  </div>
);

export default SznsTeam;
