import React from "react";

const LaunchPartner = ({
  name = "",
  leftImgUrl = "",
  rightImgUrl = "",
  profileImgUrl = "",
  twitter = "",
}) => (
  <div className="w-full">
    <h1 className="text-center text-dark-light-grey">{name}</h1>
    <a href={twitter} target="_blank">
      <div className="relative w-full h-full mt-80 md:mt-32">
        <div className="flex flex-row space-x-16 justify-between" style={{}}>
          <div className="w-full border border-primary-dark-grey rounded-sm">
            <img
              className="w-full h-full object-contain"
              src={leftImgUrl}
              style={{ borderRadius: "inherit" }}
            />
          </div>
          <div className="hidden md:block w-full border border-primary-dark-grey rounded-sm">
            <img
              className="w-full h-full object-contain"
              src={rightImgUrl}
              style={{ borderRadius: "inherit" }}
            />
          </div>
        </div>
        <div
          className="absolute -top-full md:top-0 bottom-0 left-0 right-0 m-auto rounded-full border-dark-background bg-dark-background w-116 h-116 lg:w-164 lg:h-164"
          style={{
            borderWidth: "16px",
          }}
        >
          <img
            className="object-cover w-full h-full"
            src={profileImgUrl}
            style={{ borderRadius: "inherit" }}
          />
        </div>
      </div>
    </a>
  </div>
);

export default LaunchPartner;
