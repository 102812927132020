import React from "react";
import Member from "./member";

import AxieKingImg from "../../../images/landingPage/advisors/axieking@2x.jpg";
import SantiagoSantosImg from "../../../images/landingPage/advisors/santiago_santos@2x.jpeg";
import StevekImg from "../../../images/landingPage/advisors/steveklbnf@2x.jpg";
import MakiImg from "../../../images/landingPage/advisors/0xMaki@2x.jpg";

const AdvisorTeam = () => (
  <div className="flex flex-col">
    <div className="landing-subtitle mb-48 text-center">advisors</div>
    <div className="flex flex-row flex-wrap gap-24 xlg:gap-48 team-container mx-auto justify-center">
      <Member image={AxieKingImg} social="AxieKing" />
      <Member image={SantiagoSantosImg} social="santiagoroel" />
      <Member image={StevekImg} social="steveklbnf" />
      <Member image={MakiImg} social="0xMaki" />
    </div>
  </div>
);

export default AdvisorTeam;
