import React from "react";
import AdvisorTeam from "./advisorTeam";
import AuditTeam from "./auditTeam";
import InvestorTeam from "./investorTeam";
import SznsTeam from "./sznsTeam";

const Team = () => (
  <div className="w-full team-bg flex flex-col items-center py-72 lg:py-96">
    <div
      className="flex flex-col items-center space-y-64 md:space-y-72 lg:space-y-96"
      style={{ maxWidth: "1440px" }}
    >
      <SznsTeam />
      <AdvisorTeam />
      <InvestorTeam />
      <AuditTeam />
    </div>
  </div>
);

export default Team;
