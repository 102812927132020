import React from "react";
import HomeBanner from "../components/landing/homeBanner";
import Layout from "../components/layout";
import HowItWorks from "../components/landing/howItWorks/howItWorks";

import "../components/landing/landing.css";
import Team from "../components/landing/team/team";
import LaunchPartnerContainer from "../components/landing/launchPartners/launchPartnerContainer";
import SEO from "../components/seo";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

const IndexPage = () => {
  return (
    <div className="flex flex-col h-screen w-screen bg-dark-background overflow-y-auto no-scrollbar">
      <SEO title="Curate NFT albums and build communities" />
      <div className="flex w-full justify-center">
        <div
          className="w-full bg-dark-background p-16 md:p-32"
          style={{ maxWidth: "1440px" }}
        >
          <Header />
        </div>
      </div>
      <div>
        <HomeBanner />
        <HowItWorks />
        <LaunchPartnerContainer />
        <Team />
        <div className="flex justify-center w-full bg-black">
          <div
            className="flex bg-black pt-48 sm:px-16 sm:pb-32 md:pb-16 lg:pb-32 md:px-32 w-full"
            style={{ maxWidth: "1440px" }}
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
