import React from "react";
import Member from "./member";

import PeckShieldImg from "../../../images/landingPage/audit/peckshield@2x.jpg";

const AuditTeam = () => (
  <div className="flex flex-col">
    <div className="landing-subtitle mb-48 text-center">Audited by</div>
    <div className="flex flex-row flex-wrap gap-24 xlg:gap-48 team-container mx-auto justify-center">
      <Member image={PeckShieldImg} social="peckshield" />
    </div>
  </div>
);

export default AuditTeam;
