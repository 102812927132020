import React from "react";
import Member from "./member";

import FrameworkImg from "../../../images/landingPage/investors/framework@2x.png";
import DragonFlyImg from "../../../images/landingPage/investors/dragonfly@2x.png";
import DefiImg from "../../../images/landingPage/investors/defi-alliance@2x.png";
import PearImg from "../../../images/landingPage/investors/pearlogo.png";
import LongHashImg from "../../../images/landingPage/investors/longhash@2x.png";
import CypherImg from "../../../images/landingPage/investors/cypher-capital@2x.png";
import HanDaoImg from "../../../images/landingPage/investors/hanDAO@2x.jpg";

const InvestorTeam = () => (
  <div className="flex flex-col">
    <div className="landing-subtitle mb-48 text-center">investors</div>
    <div className="flex flex-row flex-wrap gap-24 xlg:gap-48 team-container mx-auto justify-center">
      <Member image={FrameworkImg} social="hiFramework" />
      <Member image={DragonFlyImg} social="dragonfly_cap" />
      <Member image={DefiImg} social="defialliance" />
      <Member image={PearImg} social="pearvc" />
      <Member image={LongHashImg} social="LongHashVC" />
      <Member image={CypherImg} social="CypherCapital" />
      <Member image={HanDaoImg} social="HanDAO" />
    </div>
  </div>
);

export default InvestorTeam;
